import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { toast } from 'react-toastify';
import Loading from '../../../Common/Loading';

import { Column } from '../../../Common/styled/Groups';
import { GreyLabel } from '../../../Common/styled/GreyLabel';
import { ExtraBold } from '../../../Common/styled/Text';
import { Margin } from '../../../Common/styled/Margins';
import { GET_HOSPITAL_TEAM } from '../../../../graphql/queries';
import { DELETE_HOSPITAL_TEAM_MEMBER } from '../../../../graphql/mutations';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles(() => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: 15,
      width: '100%',
      maxWidth: 600,
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
}));

export const DeleteTeamMemberDialog = ({ open, onClose, refetch, teamMember = {} }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const hospitalUuid = useSelector((state) => state.hospital.uuid);
  const teamMemberUuid = teamMember && teamMember.uuid;
  const teamMemberName = teamMember && teamMember.user && `${teamMember.user.name} ${teamMember.user.surname}`;

  const [deleteHospitalTeamMember, { loading }] = useMutation(DELETE_HOSPITAL_TEAM_MEMBER, {
    onCompleted() {
      refetch();
      onClose();
      toast(
        `${teamMemberName}'s team member has been deleted from the Hospital.`,
        { className: 'toast-success' },
      );
    },
    onError: () => {
      toast(
        'There was an error deleting the team member. Please try again.',
        { className: 'toast-error' },
      );
    },
    refetchQueries: [{
      query: GET_HOSPITAL_TEAM,
      variables: {
        uuid: hospitalUuid,
      },
    }],
    awaitRefetchQueries: true,
  });

  const handleDelete = () => {
    deleteHospitalTeamMember({ variables: { input: teamMemberUuid } });
  };

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>{t('loading.removing.team.member')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('team.member.delete.confirmation.message')}
        </DialogContentText>
        <Margin mx-0 mt-5>
          <Column>
            <GreyLabel>{`${t('team.member.name')}:`}</GreyLabel>
            <ExtraBold>{teamMemberName}</ExtraBold>
          </Column>
        </Margin>
      </DialogContent>
      {loading && <Loading />}
      <DialogActions>
        <Button
          variant="outlined"
          className={classes.button}
          color="primary"
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          color="primary"
          onClick={handleDelete}
        >
          {t('delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
