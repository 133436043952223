import React, { forwardRef, useState } from 'react';

import { useSelector } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/react-hooks';
import { alpha, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

import Loading from '../../../Common/Loading';
import { Margin } from '../../../Common/styled/Margins';
import { Spacer } from '../../../Common/styled/Spacer';
import { Row, Column } from '../../../Common/styled/Groups';
import { Form } from '../../../Common/styled/Form';
import { TextFieldSelectUI } from '../../../../componentsUI/TextFieldSelect';
import { GET_HOSPITAL_TEAM } from '../../../../graphql/queries';
import { SET_TEAM_MEMBER_ROLE } from '../../../../graphql/mutations';
import { parseGraphqlErrors } from '../../../../utils/FormikUtils';
import { isEmpty } from '../../../../utils/ObjectUtils';
import { roleOptions } from '../TeamUtils';
import { ExtraBold } from '../../../Common/styled/Text';
import { GreyLabel } from '../../../Common/styled/GreyLabel';
import { canI } from '../../../../casl/Can';
import { AlertUI } from '../../../../componentsUI/Alert';

const Transition = forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paper': {
      padding: 15,
      width: '100%',
      maxWidth: 800,
    },
    '& .MuiTypography-h6': {
      fontWeight: 600,
    },
  },
  button: {
    margin: '0 10px',
    padding: '8px 25px',
    fontWeight: 700,
  },
  label: {
    color: alpha(theme.palette.text.primary, 0.54),
  },
  helperTextRoot: {
    color: theme.palette.error.main,
  },
}));

const validRole = (member) => !!roleOptions.find((option) => option.value === member.role);

export const EditTeamMemberDialog = ({ open, onClose, teamMember, hospital }) => {
  if (!teamMember) return null;
  if (!validRole(teamMember)) return null;

  const { t } = useTranslation();
  const hospitalUuid = useSelector((state) => state.hospital.uuid);
  const [serverError, setServerError] = useState(false);
  const classes = useStyles();

  const [setTeamMemberRole, { loading }] = useMutation(SET_TEAM_MEMBER_ROLE, {
    onCompleted: ({ setHospitalTeamMemberRole: updatedTeamMember }) => {
      onClose();
      toast(
        `${updatedTeamMember.user.name} ${updatedTeamMember.user.surname}'s role has been updated.`,
        { className: 'toast-success' },
      );
    },
    onError: (errors) => {
      toast('There was an error updating the role. Please try again.', { className: 'toast-error' });
      if (errors.graphQLErrors && errors.graphQLErrors.length) setServerError(errors.graphQLErrors[0].message);
    },
    refetchQueries: [{ query: GET_HOSPITAL_TEAM, variables: { uuid: hospitalUuid } }],
    awaitRefetchQueries: true,
  });

  const handleSubmit = async (form, { setErrors }) => {
    setServerError(false);
    try {
      await setTeamMemberRole({
        variables: {
          input: {
            uuid: teamMember.uuid,
            role: form.role,
          },
        },
      });
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (
        Object.keys(formikErrors).length === 0
        && formikErrors.constructor === Object
      ) {
        setServerError(true);
      } else {
        setErrors(formikErrors);
      }
    }
  };

  const validationSchema = Yup.object().shape({
    role: Yup.string(),
  });

  const initialTeamMember = (isEmpty(teamMember)) ? {} : {
    role: teamMember ? teamMember.role || '' : '',
  };

  const teamMemberName = teamMember.user && `${teamMember.user.name} ${teamMember.user.surname}`;
  const canEdit = canI('manage', hospital);

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
    >
      <DialogTitle>{t('edit.team.member.role')}</DialogTitle>
      <DialogContent>
        {isEmpty(teamMember) ? (
          <Loading />
        ) : (
          <Formik
            initialValues={initialTeamMember}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <Form autoComplete="off" id="editTeamRole">
                <Margin mx-0 my-0>
                  <Column>
                    <GreyLabel>{`${t('team.member.name')}:`}</GreyLabel>
                    <ExtraBold>{teamMemberName}</ExtraBold>
                  </Column>
                </Margin>
                <Margin mx-0>
                  <Row>
                    <Column>
                      <TextFieldSelectUI
                        name="role"
                        label={t('role')}
                        props={props}
                        options={roleOptions}
                      />
                    </Column>
                    <Spacer />
                    <Column />
                  </Row>
                </Margin>

                <Margin mx-0 mt-5 mb-4>
                  <Row>
                    {serverError ? <AlertUI severity="error" title="Error">{serverError}</AlertUI> : null}
                  </Row>
                </Margin>
              </Form>
            )}
          </Formik>
        )}
      </DialogContent>
      {loading && <Loading />}
      <DialogActions>
        <Button
          variant="outlined"
          className={classes.button}
          disabled={loading}
          color="primary"
          onClick={onClose}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="contained"
          className={classes.button}
          disabled={loading || !canEdit}
          color="primary"
          type="submit"
          form="editTeamRole"
        >
          {t('update')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
