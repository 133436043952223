import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useQuery } from '@apollo/react-hooks';
import { Container, Menu, MenuItem } from '@material-ui/core';
import { Add, ArrowDropDown, ArrowDropUp, Autorenew, Block, Delete, Settings, Sort } from '@material-ui/icons';
import { Navbar } from '../../Navbar/styled/NavbarStyles';
import { PageContent } from '../../Common/styled/PageContent';
import { GET_HOSPITAL_TEAM } from '../../../graphql/queries';
import { SectionBar } from '../../../componentsUI/SectionBar';
import { isEmpty, sortGraphQueryList } from '../../../utils/ObjectUtils';
import Loading from '../../Common/Loading';
import { CardWrapperUI } from '../../Common/styled/CardWrapperUI';
import TableCollapse from '../../../componentsUI/tableCollapse';
import { NoFilesWrapper } from '../../Common/FileCard/styled/NoFilesWrapper';
import { Span } from '../../Common/styled/Text';
import { GreyLabel } from '../../Common/styled/GreyLabel';
import { NewTeamMemberDialog } from './modal/NewTeamMemberDialog';
import { EditTeamMemberDialog } from './modal/EditTeamMemberDialog';
import { DeleteTeamMemberDialog } from './modal/DeleteTeamMemberDialog';
import { AvailableHospitalUsers, OWNER_ROLE } from '../../../utils/HospitalMemberUtils';
import { cannotI } from '../../../casl/Can';

const fieldNames = [
  { label: 'name', id: 'user.name', width: 120, field: 'NAME', direction: 'ASC' },
  { label: 'surname', id: 'user.surname', width: 120, field: 'SURNAME', direction: 'ASC' },
  { label: 'email', id: 'user.email', width: 175, field: 'EMAIL', direction: 'ASC' },
  { label: 'role', id: 'role', width: 250, format: 'LOCALETOUPPER' },
];

export const Team = () => {
  const { t } = useTranslation();
  const hospital = useSelector((state) => state.hospital);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selected, setSelected] = useState([]);
  const [showNewModal, setShowNewModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUnregistered, setShowUnregistered] = useState(false);
  const openMenu = Boolean(anchorEl);

  const sortData = {
    default: { field: 'NAME', direction: 'ASC' },
    fields: [{ key: 'NAME', text: 'Name' }, { key: 'SURNAME', text: 'Surname' }, { key: 'EMAIL', text: 'Email' }],
  };
  const [sortByField, setSortingField] = useState(sortData.default.field);
  const [sortByDirection, setSortingDirection] = useState(sortData.default.direction);

  const { data, loading, refetch } = useQuery(
    GET_HOSPITAL_TEAM, {
      variables: {
        uuid: hospital.uuid,
      },
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const reverseDirection = () => (sortByDirection === 'ASC' ? 'DESC' : 'ASC');

  const handleOrderBy = (value) => {
    if (value.field) {
      if (value.field === sortByField) {
        setSortingDirection(reverseDirection());
      } else {
        setSortingDirection(value.direction);
      }

      setSortingField(value.field);
      setAnchorEl();
      setSelected([]);
    }
  };

  const getTitle = (tm) => (
    <Span>
      {`${tm.user.name} ${tm.user.surname}`}
      <GreyLabel><small>{` - ${t(tm.role.toLowerCase()).toUpperCase()}`}</small></GreyLabel>
    </Span>
  );

  const getSubTitle = (tm) => (
    `${tm.user.email}`
  );

  const handleToggleView = () => {
    setShowUnregistered(!showUnregistered);
  };

  const handleCloseNew = () => {
    setShowNewModal(false);
    refetch();
  };

  const handleCloseEdit = () => {
    setShowEditModal(false);
    refetch();
  };

  const handleCloseDelete = () => {
    setSelected([]);
    setShowDeleteModal(false);
    refetch();
  };

  const handleOpenEdit = (event, index) => {
    event.stopPropagation();
    setSelected([index]);
    setShowEditModal(true);
  };

  const handleOrder = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const tableRowConfigureButton = {
    Icon: Settings,
    tooltip: 'configure.role',
    disabled: cannotI('manage', hospital),
  };

  const OrderIcon = ({ className, direction }) => (
    (direction === 'ASC') ? <ArrowDropDown className={className} /> : <ArrowDropUp className={className} />
  );

  const allUsers = data && data.hospital && AvailableHospitalUsers(data.hospital, false);
  const kcUsers = data && data.hospital && AvailableHospitalUsers(data.hospital, true);
  const users = data && data.hospital && AvailableHospitalUsers(data.hospital, showUnregistered);
  const unregisteredButton = (allUsers && allUsers.length) !== (kcUsers && kcUsers.length);
  const members = sortGraphQueryList(users, fieldNames, sortByField, sortByDirection);
  const member = (members && selected.length > 0) && members[selected[0]];
  const disableButtonsCondition = selected.length === 0 || (member && member.role === OWNER_ROLE);
  const subtitle = allUsers && `${allUsers.length} ${t('users').toLowerCase()}`;

  let buttons = [
    { name: 'team.add.member', icon: Add, I: 'manage', data: hospital, handleClick: () => setShowNewModal(true), disabled: false },
    { name: 'divider2', type: 'divider' },
    { name: 'configure.role', icon: Settings, I: 'manage', data: hospital, handleClick: () => setShowEditModal(true), disabled: disableButtonsCondition },
    { name: 'delete.member', icon: Delete, I: 'manage', data: hospital, handleClick: () => setShowDeleteModal(true), disabled: disableButtonsCondition },
    { name: 'divider2', type: 'divider' },
  ];

  if (unregisteredButton) {
    buttons = [
      ...buttons,
      { name: showUnregistered ? 'show.unregistered' : 'hide.unregistered', icon: Block, handleClick: handleToggleView, disabled: false },
    ];
  }

  buttons = [
    ...buttons,
    { name: 'refresh', icon: Autorenew, handleClick: () => refetch(), disabled: false },
    { name: 'sort.by', icon: Sort, handleClick: handleOrder, disabled: false },
  ];

  return (
    <>
      <Navbar>
        <NewTeamMemberDialog open={showNewModal} onClose={handleCloseNew} refetch={refetch} />
        <EditTeamMemberDialog open={showEditModal} onClose={handleCloseEdit} teamMember={member} hospital={data.hospital} />
        <DeleteTeamMemberDialog open={showDeleteModal} onClose={handleCloseDelete} teamMember={member} refetch={refetch} />

        <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null)}>
          {fieldNames.filter((filter) => !!filter.field).map((item) => (
            <MenuItem
              key={item.id}
              onClick={() => handleOrderBy(item)}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span style={{ width: 150 }}>{t(item.label)}</span>
              {(item.field === sortByField) && <OrderIcon direction={sortByDirection} />}
            </MenuItem>
          ))}
        </Menu>

        <SectionBar
          title="hospital.settings.team.manage"
          subtitle={subtitle}
          items={buttons}
        />
      </Navbar>
      <Container maxWidth="lg">
        <PageContent>
          {loading && isEmpty(data) ? <Loading /> : (
            <CardWrapperUI>
              {members ? (
                <TableCollapse
                  fieldNames={fieldNames}
                  items={members}
                  GetTitle={getTitle}
                  GetSubtitle={getSubTitle}
                  // GetCollapse={TeamManagementDataCollapse}
                  selected={selected}
                  setSelected={setSelected}
                  rowActionButton={tableRowConfigureButton}
                  handleGoto={handleOpenEdit}
                  order={{ field: sortByField, direction: sortByDirection }}
                  handleOrder={handleOrderBy}
                />
              ) : <NoFilesWrapper>{t('no.team.members')}</NoFilesWrapper>}
            </CardWrapperUI>
          )}
        </PageContent>
      </Container>
    </>
  );
};
